import { BackButton, NextButton } from "../Components/Button";
import {
  BottomButtonContainer,
  TopButtonContainer,
} from "../Components/ButtonContainer";
import { useGlobalContext } from "../GlobalContext";
import "./EducationPage.css";

function EducationPage(props: { next: any; setSlideIndex?: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div
      id=""
      className="landing-container"
      style={{ alignItems: "flex-start" }}
    >
      <TopButtonContainer nextButtonAction={() => {}} />

      <div
        style={{
          marginLeft: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            marginRight: "9%",
          }}
        >
          {!isSpanish ? (
            <>
              <h1>Five Path Attributes</h1>
              <p>
                Description for each five path attributes will be shown,
                followed by a review session.
              </p>
            </>
          ) : (
            <>
              <h1>Los Cinco Atributos Del Camino</h1>
              <p>
                A continuación se presentará una descripción de cada uno de los
                cinco atributos del camino, seguido por un breve cuestionario.
              </p>
            </>
          )}
        </div>

        {!isSpanish ? (
          <>
            <div className="list-section">
              <div className="list-item">
                <div className="circle">1</div>
                <h2>Walk Time</h2>
              </div>
              <div className="list-item">
                <div className="circle">2</div>
                <h2>Sidewalk Condition</h2>
              </div>
              <div className="list-item">
                <div className="circle">3</div>
                <h2>Crime Safety</h2>
              </div>
              <div className="list-item">
                <div className="circle">4</div>
                <h2>Traffic Safety</h2>
              </div>
              <div className="list-item">
                <div className="circle">5</div>
                <h2>Thermal Comfort</h2>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="list-section">
              <div className="list-item">
                <div className="circle">1</div>
                <h2>Tiempo de Caminata</h2>
              </div>
              <div className="list-item">
                <div className="circle">2</div>
                <h2>Condición de la Acera</h2>
              </div>
              <div className="list-item">
                <div className="circle">3</div>
                <h2>Seguridad en Relación al Crimen</h2>
              </div>
              <div className="list-item">
                <div className="circle">4</div>
                <h2>Seguridad Vial</h2>
              </div>
              <div className="list-item">
                <div className="circle">5</div>
                <h2>Comodidad Térmica</h2>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

export default EducationPage;
