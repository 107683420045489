import {
  BackButton,
  CloseButton,
  NextButton,
  SpanishButton,
} from "../Components/Button";
import LevelTag from "../Components/LevelTag";
import traffic_low_1 from "../Assets/traffic_low_1.png";
import traffic_low_2 from "../Assets/traffic_low_2.png";
import traffic_low_3 from "../Assets/traffic_low_3.png";
import traffic_moderate_1 from "../Assets/traffic_moderate_1.png";
import traffic_moderate_2 from "../Assets/traffic_moderate_2.png";
import traffic_moderate_3 from "../Assets/traffic_moderate_3.png";
import traffic_high_1 from "../Assets/traffic_high_1.png";
import traffic_high_2 from "../Assets/traffic_high_2.png";
import traffic_high_3 from "../Assets/traffic_high_3.png";
import traffic_safety from "../Assets/traffic_safety.png";
import { CSSProperties, useState } from "react";
import ProgressBar from "../Components/ProgressBar";
import { useGlobalContext } from "../GlobalContext";
import React from "react";

function TrafficSafety(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  const buttonStyle = {
    padding: "10px 50px",
    fontSize: "20px",
    border: "none",
    borderRadius: "300px",
    fontWeight: "bold",
    color: "white",
    margin: "0 10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
  };

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {!isSpanish ? (
        <>
          <ProgressBar
            steps={[
              "Walk Time",
              "Sidewalk Condition",
              "Crime Safety",
              "Traffic Safety",
              "Thermal Comfort",
            ]}
            currentStep={4}
          />
        </>
      ) : (
        <>
          <ProgressBar
            steps={[
              "Tiempo de Caminata",
              "Condición de la Acera",
              "Seguridad en Relación al Crimen",
              "Seguridad Vial",
              "Comodidad Térmica",
            ]}
            currentStep={4}
          />
        </>
      )}

      <div className="content-container">
        <div className="text-content">
          <h1 className="emoji-header" style={{ marginBottom: "-6%" }}>
            🚥
          </h1>

          {!isSpanish ? (
            <>
              <h1>4. Traffic Safety</h1>
              <p>
                Traffic safety refers to an individual’s general sense of safety
                from traffic while walking on a path.
              </p>
              <p>
                There are three levels for traffic safety: low safe, moderate
                safe, and high safe.
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button style={{ ...buttonStyle, backgroundColor: "#ff4d4d" }}>
                  Low safe
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#ffbf00" }}>
                  Moderate safe
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#00b300" }}>
                  High safe
                </button>
              </div>
            </>
          ) : (
            <>
              <h1>4. Seguridad Vial</h1>
              <p>
                La seguridad vial se refiere a la sensación general de seguridad
                de una persona frente al tráfico mientras camina.
              </p>
              <p>Hay tres niveles de seguridad vial::</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button style={{ ...buttonStyle, backgroundColor: "#ff4d4d" }}>
                  Baja seguridad
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#ffbf00" }}>
                  Seguridad moderada
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#00b300" }}>
                  Alta seguridad
                </button>
              </div>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={traffic_safety} alt="Traffic Safety" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function TrafficSafetyLow(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  const columnStyle: CSSProperties = {
    width: "30%",
    margin: "0 15px",
    textAlign: "center",
  };

  return (
    <div className="landing-container" style={{ textAlign: "center" }}>
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div style={{ alignSelf: "flex-start", marginLeft: "4%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "-5%",
          }}
        >
          {!isSpanish ? (
            <>
              {" "}
              <h2 style={{ marginRight: "10px" }}>Traffic Safety</h2>
              <LevelTag text="Low safe" level={1} />
            </>
          ) : (
            <>
              <h2 style={{ marginRight: "10px" }}>Seguridad Vial</h2>
              <LevelTag text="Baja Seguridad" level={1} />
            </>
          )}
        </div>
        <p style={{ marginBottom: "-2%" }}>
          {!isSpanish ? (
            <>Path with low traffic safety has:</>
          ) : (
            <>Un camino de baja seguridad vial tiene:</>
          )}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "90%",
        }}
      >
        {!isSpanish ? (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>High</strong> traffic speed
              </p>
              <img
                src={traffic_low_1}
                alt="High traffic speed"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) <strong>Narrow</strong> sidewalk buffer
              </p>
              <img
                src={traffic_low_2}
                alt="Narrow sidewalk buffer"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) <strong>Long</strong> crosswalks
              </p>
              <img
                src={traffic_low_3}
                alt="Long crosswalks"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        ) : (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>Alta</strong> velocidad del tráfico
              </p>
              <img
                src={traffic_moderate_1}
                alt="Moderate traffic speed"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) Acera <strong>estrecha</strong>
              </p>
              <img
                src={traffic_moderate_2}
                alt="Adequate sidewalk buffer"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) Cruces peatonales <strong>largos</strong>
              </p>
              <img
                src={traffic_moderate_3}
                alt="Moderate crosswalks"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        )}
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function TrafficSafetyModerate(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  const columnStyle: CSSProperties = {
    width: "30%",
    margin: "0 15px",
    textAlign: "center",
  };
  return (
    <div className="landing-container" style={{ textAlign: "center" }}>
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div style={{ alignSelf: "flex-start", marginLeft: "4%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "-5%",
          }}
        >
          {!isSpanish ? (
            <>
              <h2 style={{ marginRight: "10px" }}>Traffic Safety</h2>
              <LevelTag text="Moderate safe" level={2} />
            </>
          ) : (
            <>
              <h2 style={{ marginRight: "10px" }}>Seguridad Vial</h2>
              <LevelTag text="Moderada" level={2} />
            </>
          )}
        </div>
        <p style={{ marginBottom: "-2%" }}>
          {!isSpanish ? (
            <> Path with moderate traffic safety has:</>
          ) : (
            <>Un camino de seguridad vial moderada tiene:</>
          )}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "90%",
        }}
      >
        {!isSpanish ? (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>Moderate</strong> traffic speed
              </p>
              <img
                src={traffic_moderate_1}
                alt="Moderate traffic speed"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) <strong>Adequate</strong> sidewalk buffer
              </p>
              <img
                src={traffic_moderate_2}
                alt="Adequate sidewalk buffer"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) <strong>Moderate</strong> crosswalks
              </p>
              <img
                src={traffic_moderate_3}
                alt="Moderate crosswalks"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        ) : (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) Velocidad del tráfico <strong>moderada</strong>
              </p>
              <img
                src={traffic_moderate_1}
                alt="Moderate traffic speed"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) Acera <strong>adecuada</strong>
              </p>
              <img
                src={traffic_moderate_2}
                alt="Adequate sidewalk buffer"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) Cruces peatonales <strong>moderados</strong>
              </p>
              <img
                src={traffic_moderate_3}
                alt="Moderate crosswalks"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        )}
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function TrafficSafetyHigh(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  const columnStyle: CSSProperties = {
    width: "30%",
    margin: "0 15px",
    textAlign: "center",
  };
  return (
    <div className="landing-container" style={{ textAlign: "center" }}>
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div style={{ alignSelf: "flex-start", marginLeft: "4%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "-5%",
          }}
        >
          {!isSpanish ? (
            <>
              <h2 style={{ marginRight: "10px" }}>Traffic Safety</h2>
              <LevelTag text="High safe" level={3} />
            </>
          ) : (
            <>
              <h2 style={{ marginRight: "10px" }}>Seguridad Vial</h2>
              <LevelTag text="Alta Seguridad" level={3} />
            </>
          )}
        </div>
        <p style={{ marginBottom: "-2%" }}>
          {!isSpanish ? (
            <> Path with high traffic safety has:</>
          ) : (
            <>El camino de alta seguridad vial tiene</>
          )}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "90%",
        }}
      >
        {!isSpanish ? (
          <>
            {" "}
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>Low</strong> traffic speed
              </p>
              <img
                src={traffic_high_1}
                alt="Low traffic speed"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) <strong>Wide</strong> sidewalk buffer
              </p>
              <img
                src={traffic_high_2}
                alt="Wide sidewalk buffer"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) <strong>Short</strong> crosswalks
              </p>
              <img
                src={traffic_high_3}
                alt="Short crosswalks"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        ) : (
          <>
            {" "}
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>Baja</strong> velocidad del tráfico
              </p>
              <img
                src={traffic_high_1}
                alt="Low traffic speed"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) Acera <strong>amplia</strong>
              </p>
              <img
                src={traffic_high_2}
                alt="Wide sidewalk buffer"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) Cruces peatonales <strong>cortos</strong>
              </p>
              <img
                src={traffic_high_3}
                alt="Short crosswalks"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        )}
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function TrafficSafetyTable() {
  const [currentImageIndex, setCurrentImageIndex] = useState([0, 0, 0]);
  const { isSpanish } = useGlobalContext();

  const columnStyle: CSSProperties = {
    width: "50%",
    margin: "10px",
    padding: "10px",
    boxSizing: "border-box",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#f0f0f0",
  };

  const listStyle: CSSProperties = {
    fontSize: "1.5em",
    height: "4em",
    lineHeight: "1.2",
    marginBottom: "20px",
  };

  const images = [
    [traffic_low_1, traffic_low_2, traffic_low_3],
    [traffic_moderate_1, traffic_moderate_2, traffic_moderate_3],
    [traffic_high_1, traffic_high_2, traffic_high_3],
  ];

  const handlePrevImage = (columnIndex: number) => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = [...prevIndex];
      newIndex[columnIndex] =
        (prevIndex[columnIndex] - 1 + images[columnIndex].length) %
        images[columnIndex].length;
      return newIndex;
    });
  };

  const handleNextImage = (columnIndex: number) => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = [...prevIndex];
      newIndex[columnIndex] =
        (prevIndex[columnIndex] + 1) % images[columnIndex].length;
      return newIndex;
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        marginBottom: "1%",
      }}
    >
      <div className="three-col-column" style={columnStyle}>
        {!isSpanish ? (
          <>
            {" "}
            <LevelTag text="Low safe" level={1} />
            <ul style={listStyle}>
              <li>
                <strong>High</strong> traffic speed
              </li>
              <li>
                <strong>Narrow</strong> sidewalk buffer
              </li>
              <li>
                <strong>Long</strong> crosswalks
              </li>
            </ul>
          </>
        ) : (
          <>
            <LevelTag text="Baja Seguridad" level={1} />
            <ul style={listStyle}>
              <li>
                <strong>Alta</strong> velocidad del tráfico
              </li>
              <li>
                <strong>Acera</strong> estrecha
              </li>
              <li>
                Cruces peatonales <strong>largos</strong>
              </li>
            </ul>
          </>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => handlePrevImage(0)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &lt;
          </button>
          <img
            src={images[0][currentImageIndex[0]]}
            alt="High traffic speed, narrow sidewalk buffer, long crosswalks"
            style={{
              height: "250px",
              width: "250px",
              objectFit: "cover",
              margin: "10px 0",
              borderRadius: "3%",
            }}
          />
          <button
            onClick={() => handleNextImage(0)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &gt;
          </button>
        </div>
      </div>

      <div className="three-col-column" style={columnStyle}>
        {!isSpanish ? (
          <>
            {" "}
            <LevelTag text="Moderate safe" level={2} />
            <ul style={listStyle}>
              <li>
                <strong>Moderate</strong> traffic speed
              </li>
              <li>
                <strong>Adequate</strong> sidewalk buffer
              </li>
              <li>
                <strong>Moderate</strong> crosswalks
              </li>
            </ul>
          </>
        ) : (
          <>
            {" "}
            <LevelTag text="Moderada" level={2} />
            <ul style={listStyle}>
              <li>
                Velocidad del tráfico <strong>moderada</strong>
              </li>
              <li>
                Acera <strong>adecuada</strong>
              </li>
              <li>
                Cruces peatonales <strong>moderados</strong>
              </li>
            </ul>
          </>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => handlePrevImage(1)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 40px" }}
          >
            &lt;
          </button>
          <img
            src={images[1][currentImageIndex[1]]}
            alt="Moderate traffic speed, adequate sidewalk buffer, moderate crosswalks"
            style={{
              height: "250px",
              width: "250px",
              objectFit: "cover",
              margin: "10px 0",
              borderRadius: "3%",
            }}
          />
          <button
            onClick={() => handleNextImage(1)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &gt;
          </button>
        </div>
      </div>

      <div className="three-col-column" style={columnStyle}>
        {!isSpanish ? (
          <>
            {" "}
            <LevelTag text="High safe" level={3} />
            <ul style={listStyle}>
              <li>
                <strong>Low</strong> traffic speed
              </li>
              <li>
                <strong>Wide</strong> sidewalk buffer
              </li>
              <li>
                <strong>Short</strong> crosswalks
              </li>
            </ul>
          </>
        ) : (
          <>
            {" "}
            <LevelTag text="Alta Seguridad" level={3} />
            <ul style={listStyle}>
              <li>
                <strong>Baja</strong> velocidad del tráfico
              </li>
              <li>
                Acera <strong>amplia</strong>
              </li>
              <li>
                Cruces peatonales <strong>cortos</strong>
              </li>
            </ul>
          </>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => handlePrevImage(2)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &lt;
          </button>
          <img
            src={images[2][currentImageIndex[2]]}
            alt="Low traffic speed, wide sidewalk buffer, short crosswalks"
            style={{
              height: "250px",
              width: "250px",
              objectFit: "cover",
              margin: "10px 0",
              borderRadius: "3%",
            }}
          />
          <button
            onClick={() => handleNextImage(2)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

function TrafficSafetySummary(props: {
  next?: any;
  back?: any;
  closeHandler?: any;
}) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {props.closeHandler && (
        <div className="top-left-button">
          <CloseButton buttonAction={props.closeHandler} />
        </div>
      )}

      <h2
        style={{
          textAlign: "left",
          width: "100%",
          marginLeft: "7%",
          marginTop: "2%",
        }}
      >
        {!isSpanish ? <>Traffic Safety</> : <>Seguridad Vial</>}
      </h2>

      <TrafficSafetyTable />

      <div className="center-buttons">
        {props.back && (
          <div className="bottom-left-button">
            <BackButton buttonAction={props.back} />
          </div>
        )}
        {props.next && (
          <div className="bottom-right-button">
            <NextButton buttonAction={props.next} />
          </div>
        )}
      </div>
    </div>
  );
}

export default TrafficSafety;
export {
  TrafficSafetyLow,
  TrafficSafetyModerate,
  TrafficSafetyHigh,
  TrafficSafetySummary,
  TrafficSafetyTable,
};
